import React, { useEffect, useState, useRef } from 'react';
import "./chatwoot.css"

const ChatWootWidget = (props) => {

  const [chatLoaded, setChatLoaded] = useState(false);
  let anchor = useRef(null)
  useEffect(() => {
    window.addEventListener('chatwoot:ready', function () {
      let chatClose = document.getElementsByClassName("woot-elements--right woot-widget-bubble woot--close")[0];
      if (chatClose)
        chatClose.parentElement.removeChild(chatClose);
      let chatBubble = document.getElementsByClassName("woot--bubble-holder")[0];
      if (chatBubble)
        chatBubble.parentElement.removeChild(chatBubble)
      let widgetHolder = document.getElementsByClassName("woot-widget-holder woot-elements--right")[0]
      if (widgetHolder)
        widgetHolder.parentElement.removeChild(widgetHolder)

      window.$chatwoot.setUser(props.loginStatus['sub'], {
        email: props.loginStatus['sub'],
        name: props.loginStatus['fullname'],
      });
    });
    window.chatwootSettings = {
      hideMessageBubble: true,
      locale: 'it', // Language to be set
    };

    const sdkScript = document.createElement('script');

    sdkScript.src = "https://app.chatwoot.com" + "/packs/js/sdk.js"
    sdkScript.async = true
    sdkScript.defer=true
    sdkScript.onload = () => {
      window.chatwootSDK.run({
        websiteToken: '3wYdn53qsy8hZm6NUbiyhHN8',
        baseUrl: "https://app.chatwoot.com"
      });
      setChatLoaded(true)
    }
    anchor.current.appendChild(sdkScript);

    return () => {
      window.chatwootSDK = null
      window.$chatwoot = null
      anchor.current.removeChild(sdkScript);

      // window.removeEventListener("chatwoot:ready")
    }
  }, [])

  useEffect(() => {
    if (chatLoaded == false) {
      return
    }
    let chatWindow = document.getElementById("chatwoot_live_chat_widget");
    if (!chatWindow)
      return
    anchor.current.appendChild(chatWindow);

    const observer = new MutationObserver((mutations, observer) => {
      chatWindow.style.height = null
    })

    observer.observe(chatWindow, { attributes: true })
  }, [chatLoaded])


  return (
    <div id='chatanchor' ref={anchor} className='chatDiv'>
    </div>
  );
};

export default ChatWootWidget;
