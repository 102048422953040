import React from 'react';
import { Container } from 'react-bootstrap';

const CourseHeader = ({
    name,
    description
}) => {
	return (
		<Container className='mt-3'>
            <h1 style={{textAlign: 'center'}}>{name}</h1>
            <h5>{description}</h5>
		</Container>
	);
};

export default CourseHeader;
