import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "../config";

class ApiService {

  async createUser_guest(
    username,
    password,
    firstname,
    lastname,
    croas,
    codiceFiscale,
    provincia,
    captchaKey
  ) {
    const params = {
        "username": username,
        "password": password,
        "is_admin": false,
        "captcha_key": captchaKey,
        "croas": croas,
        "codice_fiscale": codiceFiscale,
        "provincia": provincia
    }
    if (firstname !== "") params["first_name"] =  firstname;
    if (lastname !== "") params["last_name"] = lastname;
    return await axios.post(API_URL+'/register', params, { headers: authHeader() });
  }

  async editUser(
    username,
    password = null,
    firstname,
    lastname,
    croas,
    codiceFiscale,
    provincia,
    id
  ) {
    const params = {
        "username": username,
        "is_admin": false,
        "croas": croas,
        "codice_fiscale": codiceFiscale,
        "provincia": provincia
    }
    if (password != "") params['password'] = password
    if (firstname !== "") params["first_name"] =  firstname;
    if (lastname !== "") params["last_name"] = lastname;
    return await axios.put(API_URL+'/users/'+id, params, { headers: authHeader() });
  }

  async getUsers(){
    return axios.get(API_URL+'/users', { headers: authHeader() });
  }

  async getEvent(id){
    return axios.get(API_URL+'/events/'+id, {headers: authHeader() });
  }

  async getEventStatus(id){
    return axios.get(API_URL+'/events/'+id+'/status', {headers: authHeader() });
  }
  
  async getEventEmbed(id){
    return axios.get(API_URL+'/events/'+id+'/embed', {headers: authHeader() });
  }
  
  async sendAttendance(id){
    return axios.get(API_URL+'/attendance/'+id, {headers: authHeader() });
  }

  async getUserById(id){
    return axios.get(API_URL+'/users/'+id, { headers: authHeader() });
  }

  async getStats(){
    return axios.get(API_URL+'/userstats', { headers: authHeader() });
  }

  async verifyUser(id, key){
    return await axios.get(API_URL+`/validate?id=${id}&key=${key}`)
  }

  async verifyPasswordReset(key){
    return await axios.get(API_URL+`/resetpassword?verify_key=${key}`)
  }
  
  async resetPassword(id, key, password){
    var data = {
      "verify_key": key,
      "password": password
    }
    return await axios.post(API_URL+`/resetpassword`, data)
  }

  async resetRequest(username){
    return await axios.post(API_URL+`/resetrequest?username=${username}`)
  }
}



export default new ApiService();
