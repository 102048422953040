import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import authService from "../services/auth.service";
import { useHistory } from "react-router";
import { Row, Col } from "react-bootstrap";
import * as Sentry from "@sentry/react";

const Login = (props) => {
  let history = useHistory()
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [successfulLogin, setSuccessfulLogin] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [genericError, setGenericError] = useState(false);
  const emailField = useRef(null);


  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  //this hack should fix autofill not triggering events https://stackoverflow.com/questions/55244590/autofill-does-not-trigger-onchange/62199697#62199697
  useEffect(() => {
    let interval = setInterval(() => {
      if (emailField.current) {
        setUsername(emailField.current.value);
      }
    }, 100);
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await authService.login(username, password);
      localStorage.removeItem('authError')
      history.push("/about", { login: true })
    } catch (error) {
      setFormSubmitted(true)
      if (error.response) {
        if (error.response.status === 401) {
          setSuccessfulLogin(false);
        }
        else{
          setGenericError(true)
          Sentry.captureException(error)
          console.log(error.request.__sentry_xhr__)
          console.log(error.config)
          throw new Error(error)
        }
      }
      else {
        setGenericError(true)
        console.log(error.request.__sentry_xhr__)
        console.log(error.config)

        Sentry.captureException(error)
        throw new Error(error)
      }

    }
  };

  const displayAlert = (e) => {
    if (history.location.state) {
      if (history.location.state['failReason']) {
        return <Alert variant="danger">{history.location.state['failReason']}</Alert>
      }
      if (history.location.state['successReason']) {
        return <Alert variant="success">{history.location.state['successReason']}</Alert>
      }
      if (history.location.state['warnReason']) {
        return <Alert variant="warning">{history.location.state['warnReason']}</Alert>
      }
    }
    if (formSubmitted) {
      if(!successfulLogin){
        return <Alert variant="danger">Le credenziali non sono corrette</Alert>
      }
      if(genericError){
        return <Alert variant="danger">Si è verificato un errore imprevisto, se continui a riscontrare questo problema, contatta l'assistenza</Alert>
      }
    }
  };

  return (
    <Container>
      {displayAlert()}
      <Form onSubmit={handleSubmit} className="mt-5">
        <h2 className="mb-5 mt-5" style={{ textAlign: "center" }}>Inserisci i tuoi dati per accedere</h2>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" className="mb-3" controlId="em1">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              ref={emailField}
              name="em1"
              type="username"
              placeholder="Inserisci email"
              value={username}
              onChange={onUsernameChange}
              autoComplete="new-password"
            />
          </Form.Group>
        </Row>
        <Row className="mb-40 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="pw1">
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="password"
              name="pw1"
              placeholder="Inserisci la password"
              value={password}
              onChange={onPasswordChange}
              autoComplete="new-password"
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 mt-3 justify-content-md-center">
          <Col md="5">
            <Button
              className="col-md-12"
              size="lg"
              variant="primary"
              type="submit">
              Accedi
            </Button>
          </Col>
        </Row>
      </Form>
      <Row className="mb-3 mt-3 justify-content-md-center" md='5'>
      <p>Non riesci ad accedere?</p>
	  <Button href='/requestreset'>Reimposta la tua password</Button>
    </Row>
    </Container>
  );
};

export default Login;
