import { useCallback, useEffect } from "react"
import { useLocation } from "react-router";
import { Buffer } from 'buffer'
import { useHistory } from "react-router";

const AuthVerify = (props) => {
  let location = useLocation()
  let history = useHistory()
  
  const decodeJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const parseJwt = (token) => {
    try {
      return JSON.parse(Buffer.from(token.split('.')[1], 'base64'));
    } catch (e) {
      console.log(e)
      return null;
    }
  };
  const readLoginStatus = useCallback((user) => {
    let decoded = decodeJwt(user.access_token);
    props.setLoginStatus(decoded);
  }, [props]);

  const logoutUser = useCallback((reason) => {

    localStorage.removeItem('user');
    props.setLoginStatus({})
    localStorage.removeItem('authError')

    history.push("/login", {failReason:reason})
  }, [props, history]);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      if (!props.loginStatus['sub']) {
        readLoginStatus(user)
      }
      const decodedJwt = parseJwt(user.access_token);
      if (decodedJwt.exp * 1000 < Date.now()) {
        logoutUser("Sessione scaduta, per favore rieffettua l'accesso")
      }
      if(localStorage.getItem('authError') === "true"){
        localStorage.removeItem('authError');
        logoutUser("Sessione scaduta, per favore rieffettua l'accesso")
      }
      if(decodedJwt.is_verified === false){
        logoutUser("Utente non verificato, controlla la tua email per attivare il tuo account o contatta l'assistenza")
      }
    }
  }, [location, props, props.loginStatus, readLoginStatus, logoutUser])


  return null
}

export default AuthVerify