import './App.css';
import BootstrapNavbar from './Components/BootstrapNavbar'
import Login from './routes/login';
import About from './routes/about';
import CreateUser from './routes/createUser';
import Verify from './routes/verify';
import AuthVerify from './routes/authVerify';
import UserList from './routes/userList';
import EditUser from './routes/editUser';
import Course from './routes/course';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import { useState } from 'react';
import ErrorReporter from './routes/ErrorReporter';
import ErrorReportButton from './Components/ErrorReportButton';
import ResetPassword from './routes/resetPassword';
import RequestReset from './routes/requestReset';
import Stats from './routes/stats';



function App() {
  const [loginStatus, setLoginStatus] = useState({})
  return (
    <div className='content'>
      <Router>
      <ErrorReportButton />
        <BootstrapNavbar loginStatus = {loginStatus} setLoginStatus = {setLoginStatus}/>
        <Switch>
          <Route path='/about' render={() => <About loginStatus={loginStatus}/>} />
          <Route path='/course/:id' render={() => <Course loginStatus={loginStatus}/>} />
          <Route path='/login' render={() => <Login loginStatus={loginStatus}/>} />
          <Route path='/register' component={CreateUser}/>
          <Route path='/verify' component={Verify} />
          <Route path='/users/:id/edit' component={EditUser} />
          <Route path='/users' component={UserList} />
          <Route path='/error' component={ErrorReporter} />
          <Route path='/resetpassword' component={ResetPassword} />
          <Route path='/requestreset' component={RequestReset} />
          <Route path='/stats' component={Stats} />
          <Route path='/' render={() => <About loginStatus={loginStatus}/>} />
        </Switch>
        <AuthVerify setLoginStatus={setLoginStatus} loginStatus = {loginStatus}/>
      </Router>
    </div>
  );
}

export default App;
