import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Countdown from 'react-countdown';
import { calcTimeDelta } from 'react-countdown';
import "./CourseCountdown.css"
const CourseCountdown = ({ eventDate }) => {

    const [countDownOver, setCountDownOver] = useState(false);
    const endCountdown = () => {
        setCountDownOver(true)
    }

    useEffect(() => {
        let timeDelta = calcTimeDelta(eventDate)
        if (timeDelta.completed){
            setCountDownOver(true)
        }
    },[])
    const content = () => {
        if (countDownOver) {
            return(<div>L'evento comincerà a momenti</div>)
        }
        else {
            return (
                <div>
                    <div> Inizio previsto dell'evento tra </div>
                    <Countdown date={eventDate} onComplete={endCountdown} daysInHours={true} className="countdown" />
                    <div>Puoi attendere qui l'Inizio</div>
                </div>
            );
        }
    }

    return (
        <Container className='mt-4 mb-4 countdown-container' >
            {content()}
        </Container>
    );
};

export default CourseCountdown;
