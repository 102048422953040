import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import React, { useState, useCallback, useEffect } from "react";
import apiService from "../services/api.service";
import { Validator as CFValidator } from "@marketto/codice-fiscale-utils/dist/codice-fiscale-utils";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

const EditUser = () => {
  const [user, setUser] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [successfulRegister, setSuccessfulRegister] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [croas, setCroas] = useState("Sicilia");
  const [codiceFiscale, setCodiceFiscale] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [provincia, setProvincia] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  let history = useHistory()
  let {id} =useParams()

  useEffect(() => {
    getUser()
  }, []);

  useEffect(() =>{
    if(user){
      setUsername(user.data.username);
      setFirstname(user.data.first_name);
      setLastname(user.data.last_name);
      setCroas(user.data.croas);
      setCodiceFiscale(user.data.codice_fiscale);
      setProvincia(user.data.provincia)
    }
  }, [user])

  const getUser = async() =>{
    let user = await apiService.getUserById(id);
    setUser(user);
  }

  const checkPasswordMismatch = () => {
    if (passwordConfirm === "") {
      if (password !== "") {
        return true
      }
    }
    if (passwordConfirm === password) {
      return false
    } else {
      return true;
    }
  }
  const checkCF = () => {
    if(codiceFiscale)
      return CFValidator.codiceFiscale(codiceFiscale).valid
  }
  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const onPasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };
  const onfirstnameChange = (e) => {
    setFirstname(e.target.value);
  };
  const onLastNameChange = (e) => {
    setLastname(e.target.value);
  };
  const onCroasChange = (e) => {
    setCroas(e.target.value);
    if(e.target.value !== "Sicilia"){
      setProvincia("Altra Provincia")
    }
    else{
      setProvincia("")
    }

  };
  const onCodiceFiscaleChange = (e) => {
    setCodiceFiscale(e.target.value);
  };
  const onProvinciaChange = (e) => {
    setProvincia(e.target.value)
  };


  const handleSubmit = async (e) => {
    setButtonDisabled(true);
    setErrorMessage("");
    e.preventDefault();
    await apiService
      .editUser(
        username, password, firstname, lastname, croas, codiceFiscale, provincia, id
      )
      .then(() => {
        history.push("/about", {"successReason":"Modifica utente avvenuta con successo"})
        // setFormSubmitted(true);
        // setSuccessfulRegister(true);
      })
      .catch((error) => {
        console.log(error)
        setFormSubmitted(true);
        setSuccessfulRegister(false);

        if(error.response){
          if(error.response.data.detail){
            if(error.response.data.detail === 'Username already exists'){
              setErrorMessage("Questa email risulta già registrata, se non riesci ad effettuare l'accesso con i tuoi dati, contatta info@ceformedsrl.it")
            }
          }
        }
      });
  };

  const checkSicily = () => {
    return croas === "Sicilia"
  }


  const displayAlert = (e) => {
    if (formSubmitted) {
      if (successfulRegister) {
        return (
          <Alert variant="success">
            La registrazione è avvenuta con successo, controlla la tua email e segui le istruzioni ricevute per attivare il tuo account.
          </Alert>
        );
      }
      else {
        if(errorMessage!= ""){
          let msg = errorMessage;
        return (
          <Alert variant="danger">
            {msg}
          </Alert>
        );
        }
        else{
          return (
            <Alert variant="danger">
              Errore durante la registrazione.
            </Alert>
          );
        }
      }
    }
  };

  const resetForm = () => {
    setUsername("");
    setPassword("");
    setPasswordConfirm("");
    setFirstname("");
    setLastname("");
    setCodiceFiscale("");
    setButtonDisabled(false);
    setCroas("Sicilia")
    setProvincia("")
  }

  return (
    <Container>
      {displayAlert()}
      <Form onSubmit={handleSubmit} className="mt-3" md="2">
        <h2 className="mb-5 mt-5" style={{ textAlign: "center" }}>modifica utente</h2>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicFirstName">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Inserisci nome"
              value={firstname}
              onChange={onfirstnameChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicLastName">
            <Form.Label>Cognome</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Inserisci cognome"
              value={lastname}
              onChange={onLastNameChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Inserisci Email"
              value={username}
              onChange={onUsernameChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicPassword">
            <Form.Label>Crea Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Inserisci la password"
              value={password}
              onChange={onPasswordChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicPasswordConfirm">
            <Form.Label>Conferma Password</Form.Label>
            <Form.Control
              required= {password!==""}
              type="password"
              placeholder="Inserisci nuovamente la password"
              value={passwordConfirm}
              onChange={onPasswordConfirmChange}
              isValid={password === "" ? false : !checkPasswordMismatch}
              isInvalid={checkPasswordMismatch()}
            />
            <Form.Control.Feedback type="invalid">
              Le password non corrispondono
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicCodiceFiscale">
            <Form.Label>Codice Fiscale</Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Inserisci il tuo codice fiscale"
              value={codiceFiscale}
              onChange={onCodiceFiscaleChange}
              isValid={codiceFiscale === "" ? false : checkCF()}
              isInvalid={codiceFiscale === "" ? false : !checkCF()}
            />
            <Form.Control.Feedback type="invalid">
              Codice fiscale non valido
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5">
            <Form.Label>Seleziona il tuo Croas di appartenenza</Form.Label>
            <Form.Select
              // controlId="formBasicCroasSelect"
              value={croas}
              onChange={onCroasChange}>
              <option value="Sicilia">Sicilia</option>
              <option value="Abruzzo">Abruzzo </option>
              <option value="Basilicata">Basilicata</option>
              <option value="Calabria">Calabria</option>
              <option value="Campania">Campania</option>
              <option value="Emilia-Romagna">Emilia-Romagna</option>
              <option value="Friuli-Venezia Giulia">Friuli-Venezia Giulia</option>
              <option value="Lazio">Lazio</option>
              <option value="Liguria">Liguria</option>
              <option value="Lombardia">Lombardia</option>
              <option value="Marche">Marche</option>
              <option value="Molise">Molise</option>
              <option value="Piemonte">Piemonte</option>
              <option value="Puglia">Puglia</option>
              <option value="Sardegna">Sardegna</option>
              <option value="Toscana">Toscana</option>
              <option value="Trentino-Alto Adige">Trentino-Alto Adige</option>
              <option value="Umbria">Umbria	</option>
              <option value="Valle d'Aosta">Valle d'Aosta</option>
              <option value="Veneto">Veneto</option>
              <option value="Non iscritto">Non iscritto</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Form.Group as={Col} md="5">
            <Form.Label>Seleziona la provincia di residenza</Form.Label>
            <Form.Select
              required
              value={provincia}
              onChange={onProvinciaChange}
              disabled = {!checkSicily()}
            >
              <option disabled value={""}>Provincia</option>
              <option value="Agrigento">Agrigento</option>
              <option value="Caltanissetta">Caltanissetta</option>
              <option value="Catania">Catania</option>
              <option value="Enna">Enna</option>
              <option value="Messina">Messina</option>
              <option value="Palermo">Palermo</option>
              <option value="Ragusa">Ragusa</option>
              <option value="Siracusa">Siracusa</option>
              <option value="Trapani">Trapani</option>
              <option value="Altra Provincia" disabled={checkSicily()}>Altra Provincia</option>
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-3 justify-content-md-center">
          <Col md="5">
            <Button
              className="col-md-12"
              size="lg"
              variant="primary"
              type="submit"
              disabled={checkPasswordMismatch() || !checkCF() || buttonDisabled}>
              modifica
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default EditUser;
