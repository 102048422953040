import axios from "axios";
import { API_URL } from "../config";

class AuthService {
  async login(username, password) {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', username);
    params.append('password', password);
    params.append('scope', null)
    params.append('client_id', null)    
    params.append('client_secret', null)
    const response =  await axios.post(API_URL + "/token", params)
      if (response.data.access_token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

export default new AuthService();