import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from "react-router-dom"
import apiService from '../services/api.service';

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Verify = () => {
    let history = useHistory();
    let query = useQuery();

    useEffect(() => {
        const verifyUser = async () => {
            await apiService.verifyUser(query.get('id'), query.get('key'))
                .catch((error) => {
                    if (error.response.data.detail === "user already verified")
                        history.push('/login', { 'warnReason': "L'account è stato già validato in precedenza, puoi effettuare l'accesso con le tue credenziali" })
                    else {
                        history.push('/login', { 'failReason': "La validazione non è avvenuta con successo, se pensi che questo sia un errore contatta l'assistenza" })
                    }
                })
                .then((response) => {
                    if (response) {
                        if (response.status === 200) {
                            history.push('/login', { 'failReason': "Validazione effettuata con successo, puoi effettuare l'accesso con le tue credenziali" })
                        }
                    }
                })
        }
        verifyUser()
    }, [])



    return null
};

export default Verify;
