import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, ListGroupItem, Modal, Row } from "react-bootstrap";
// import { useHistory } from 'react-router';
import apiService from "../services/api.service";
import { v4 as uuid } from "uuid";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const Stats = () => {
    const [stats, setStats] = useState([]);

    useEffect(() => {
        getUserStats()
    }, []);

    const getUserStats = async () => {
        let stats = await apiService.getStats();
        setStats(stats);
    }

    const populate = () => {
        let elements = [];
        let total = 0
        if (stats.data) {
            stats.data["province"].forEach((provincia) => {
                total += provincia.count
                elements.push(
                    <ListGroup.Item  key={uuid()}>
                        <Container>
                            <Row className='mt-2'>
                                <Col><Row>{provincia.provincia}</Row>
                                    <Row>{provincia.count}</Row></Col>
                            </Row>
                        </Container>
                    </ListGroup.Item>
                );
            });
            return <Container>
                <h2>Utenti totali: {total}</h2>
                <Row>
                    <Col md={4}>
                        <h3>Utenti per provincia</h3>
                        <ListGroup>{elements}</ListGroup>
                    </Col>
                    <Col md={4}>
                        <h3>Utenti con email verificata</h3>
                        <ListGroup><ListGroup.Item>{stats.data.verified.count}</ListGroup.Item></ListGroup>
                    </Col>
                    <Col md={4}>
                    <h3>Utenti che hanno effettuato l'accesso</h3>
                        <ListGroup><ListGroup.Item>{stats.data.login.count}</ListGroup.Item></ListGroup>
                    </Col>
                </Row>
            </Container>;
        } else {
            elements.push(
                <Alert variant="info" key={uuid()}>
                    Non ci sono Utenti registrati
                </Alert>
            );
            return elements;
        }
    };
    return (
        <Container>
            <h1>Statistiche</h1>
            {populate()}
        </Container>
    );
};

export default Stats;
