import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router";
import { Row, Col } from "react-bootstrap";
import apiService from "../services/api.service";
import { useLocation } from "react-router-dom"


function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ResetPassword = (props) => {
  let history = useHistory()
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  let query = useQuery();

  useEffect(() => {
    const verifyToken = async () => {
      await apiService.verifyPasswordReset(query.get('key'))
      .catch((error) => {
        history.push('/login', { 'failReason': "Il link per il reset della password non è più valido, Richiedine un altro se necessario"})
      })
    }
    verifyToken()
  }, [])

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onPasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true)
    try {
      await apiService.resetPassword(query.get('id'), query.get('key'), password);
      localStorage.removeItem('authError')
      history.push('/login', { 'successReason': "Password cambiata, puoi effettiare l'accesso usando la tua nuova password" })

    } catch (error) {
      // setFormSubmitted(true)
      // if (error.response) {
      //   if (error.response.status === 401) {
      //     setSuccessfulLogin(false);
      //   }
      //   else{
      //     setGenericError(true)
      //     // Sentry.captureException(error)
      //     console.log(error.request.__sentry_xhr__)
      //     console.log(error.config)
      //     throw new Error(error)
      //   }
      // }
      // else {
      //   setGenericError(true)
      //   console.log(error.request.__sentry_xhr__)
      //   console.log(error.config)

      //   // Sentry.captureException(error)
      //   throw new Error(error)
      // }

    }
  };

  const checkPasswordMismatch = () => {
    if (passwordConfirm === "") {
      if (password !== "") {
        return true
      }
    }
    if (passwordConfirm === password) {
      return false
    } else {
      return true;
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit} className="mt-5">
        <h2 className="mb-5 mt-5" style={{ textAlign: "center" }}>Inserisci la nuova password</h2>
        <Row className="mb-40 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder="Inserisci la password"
              value={password}
              onChange={onPasswordChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-40 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicPassword">
            <Form.Label>Conferma password</Form.Label>
            <Form.Control
              required
              type="password"
              placeholder="Inserisci la password"
              value={passwordConfirm}
              onChange={onPasswordConfirmChange}
              onPaste={(e) => {e.preventDefault()}}//disable pasting
              isValid={password === "" ? false : !checkPasswordMismatch()}
              isInvalid={checkPasswordMismatch()}
            />
          </Form.Group>
        </Row>
        {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Check me out" />
        </Form.Group> */}
        <Row className="mb-3 mt-3 justify-content-md-center">
          <Col md="5">
            <Button
              className="col-md-12"
              size="lg"
              variant="primary"
              disabled={checkPasswordMismatch() || buttonDisabled}
              type="submit">
              Cambia password
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default ResetPassword;
