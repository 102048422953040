import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import ChatWootWidget from '../Components/CourseComponents/Chatwoot/chatwoot';
import CourseHeader from '../Components/CourseComponents/CourseHeader/courseHeader';
import apiService from '../services/api.service';
import CourseCountdown from '../Components/CourseComponents/Countdown/CourseCountdown';
import LiveEmbed from '../Components/CourseComponents/LiveEmbed/LiveEmbed';
import AttendanceManager from '../Components/CourseComponents/AttendanceManager/AttendanceManager';
import { useHistory } from 'react-router-dom';

const statusCheckDelay = 5000
const Course = (props) => {
    const [eventDate, setEventDate] = useState();
    const [eventName, setEventName] = useState("");
    const [eventDescription, setEventDescription] = useState("");
    const [embedLink, setEmbedLink] = useState("");
    const [eventStatus, setEventStatus] = useState("");
    let { id } = useParams();
    let history = useHistory()
    let interval



    useEffect(() => {
        if (eventStatus !== "active")
            return

        if (!embedLink) {
            apiService.getEventEmbed(id)
                .then((response) => {
                    setEmbedLink(response.data)
                })
        }
    }, [eventStatus, embedLink, id])

    const getEvent = useCallback(async () => {
        await apiService.getEvent(id)
            .then((response) => {
                let event = response?.data;
                if (event) {
                    if (event['embed_link'] !== null && event['embed_link'] !== "") {
                        setEmbedLink(event['embed_link']);
                    }
                    setEventDescription(event['description']);
                    setEventName(event['name']);
                    setEventDate(Date.parse(event['date']));

                }
            }, (error) =>{
                localStorage.setItem('authError', "true")
                history.push('/about');
            });
    }, [id]);

    const getEventStatus = useCallback(async() => {
        await apiService.getEventStatus(id)
        .then((response) => {
            if (response.data) {
                setEventStatus(response.data);
                if(response.data === 'active'){
                    clearInterval(interval);
                }
            }
        },
        (error) => {
            localStorage.setItem('authError', "true")
            history.push('/about');
        })
    }, [id])

    useEffect(() => {
        getEvent()
        getEventStatus()

        interval = setInterval(async () => {
           getEventStatus()
        }, statusCheckDelay);
        // return statusInterval
        return (() => {clearInterval(interval)})
    }, [getEvent])


    const content = () => {
        if (props.loginStatus['sub']) {
            return (
                <Container>
                    {eventStatus && eventDate ? <AttendanceManager eventDate={eventDate} eventId={id} eventStatus={eventStatus} /> : null}
                    {eventName ? <CourseHeader name={eventName} description={eventDescription} /> : null}
                    {eventStatus === 'active' && embedLink ? 
                    <div>
                        <LiveEmbed embedLink={embedLink} /> 
                        <ChatWootWidget loginStatus={props.loginStatus} /> 
                    </div>
                    : null}
                    {eventStatus !== 'active' && eventDate ? <CourseCountdown eventDate={eventDate} /> : null}
                    
                </Container>
            )
        }
    }

    return (
        <Container>
            {content()}
        </Container>
    );
};

export default Course;
