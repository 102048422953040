import  { useEffect, useState } from 'react';
import { calcTimeDelta } from 'react-countdown';
import apiService from '../../../services/api.service';
import { useHistory } from 'react-router-dom';

const AttendanceManager = ({eventDate, eventId, eventStatus}) => {
    const [attendanceStarted, setAttendanceStarted] = useState(false);
    let history = useHistory();

    useEffect(() =>{
        if (eventStatus === 'active')
            setAttendanceStarted(true)
        else{
            console.log("checktimeInterval set");
            let checktimeInterval = setInterval(() => {
                let timedelta = calcTimeDelta(eventDate);
                if(timedelta.total < (10*60*1000)){//timedelta < 10 minutes
                    setAttendanceStarted(true);
                    clearInterval(checktimeInterval);
                }
            }, 20000);//every 20 secs

            return () => {clearInterval(checktimeInterval)}
        }

    }, [])
	
    useEffect(()=>{
        if (attendanceStarted){
            //send one attendance right away
            apiService.sendAttendance(eventId)
            console.log("sendAttendanceInterval set")
            let sendAttendanceInterval = setInterval(() => {
                apiService.sendAttendance(eventId)
                .catch((error) => {
                    localStorage.setItem('authError', "true")
                    history.go(0);
                })
            }, 5*60*1000);//every 5 minutes

            return () => {console.log("sendAttendanceInterval clear"); clearInterval(sendAttendanceInterval)}
        }
    }, [attendanceStarted])

    return null
};

export default AttendanceManager;
