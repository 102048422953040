import React, { useEffect, useState } from "react";
import { Col, Container, ListGroup, Modal, Row } from "react-bootstrap";
// import { useHistory } from 'react-router';
import apiService from "../services/api.service";
import { v4 as uuid } from "uuid";
import { Alert } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const UserList = () => {
  const [users, setUsers] = useState([]);
  
  useEffect(() => {
    getUserList()
  }, []);

  const getUserList = async() =>{
    let userList = await apiService.getUsers();
    setUsers(userList);
  }

  const populate = () => {
    let elements = [];
    if (users.data) {
      users.data.forEach((user) => {
        elements.push(
          <ListGroup.Item key={uuid()}>
            <Container>
              <Row className='mt-2'>
                <Col><Row>Nome: {user.first_name} Cognome: {user.last_name}</Row>
                <Row>email: {user.username}</Row></Col>
                <Col className="justify-content-end">
                  <Button as={Link} to={'/users/'+user.id+"/edit"} className="float-end">modifica</Button>
                </Col>
              </Row>
            </Container>
          </ListGroup.Item>
        );
      });
      return <ListGroup>{elements}</ListGroup>;
    } else {
      //no active emergencies
      elements.push(
        <Alert variant="info" key={uuid()}>
          Non ci sono Utenti registrati
        </Alert>
      );
      return elements;
    }
  };
  return (
    <Container>
      <h1>Lista Utenti</h1>
      <h2>numero utenti registrati: {users.data ? users.data.length : 0}</h2>
      {populate()}
    </Container>
  );
};

export default UserList;
