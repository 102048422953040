import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { useHistory, useLocation } from "react-router";
import authService from "../../services/auth.service";
import { v4 as uuid } from "uuid";
import "./index.css"

const BootstrapNavbar = (props) => {
  //using location here forces render on location change
  // eslint-disable-next-line
  let location = useLocation();
  let history = useHistory();
  const logout = () => {
    authService.logout();
    props.setLoginStatus({})
    history.push("/about")
  }

  const populateNavbar = () => {
    let elements = [];
    elements.push(
      <Nav className="me-auto" key={uuid()}>
      {/* <Nav.Link as={Link} to="/about">
        Home
      </Nav.Link> */}
      {/* <Nav.Link href="#link">Link</Nav.Link> */}
    </Nav>
    );
    if(!props.loginStatus['sub']){//no user is logged
      elements.push(
        <Nav key={uuid()}>
          <Nav.Link as={Link} to="/login" style={{color :"#ffffff"}}>Login</Nav.Link>
        </Nav>
      );
      elements.push(
        <Nav key={uuid()}>
          <Nav.Link as={Link} to="/register" style={{color :"#ffffff"}}>Registrati</Nav.Link>
        </Nav>
      );
    }
    else{//some user is logged
      elements.push(
        <Nav key={uuid()} >
          <NavDropdown title={props.loginStatus['sub']}>
          {/* <NavDropdown.Item as={Link} to="/user">Profilo</NavDropdown.Item> */}
            {props.loginStatus['is_admin']
            ?<NavDropdown.Item as={Link} to="/users">Lista Utenti</NavDropdown.Item>
            :null}
            {props.loginStatus['is_admin']
            ?<NavDropdown.Item as={Link} to="/stats">Statistiche Utenti</NavDropdown.Item>
            :null}
            <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
          </NavDropdown>
        </Nav>
      )
    }

    return elements;
  }

  const NavbarStyle= {
    backgroundColor:"#006e89"
    }

  return (
    <Navbar style={NavbarStyle} expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/about" style={{color :"#ffffff"}}>
          <img
          src="/logocroas.png"
          height="30"
          className="d-inline-block align-top navLogo"
        />
          Webinar Croas Sicilia
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {populateNavbar()}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default BootstrapNavbar;
