import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router";
import { Row, Col } from "react-bootstrap";
import apiService from "../services/api.service";



const RequestReset = (props) => {
  let history = useHistory()
  const [username, setUsername] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonDisabled(true)
    try {
      await apiService.resetRequest(username);
      localStorage.removeItem('authError')
      history.push('/login', { 'successReason': "Richiesta inviata, controlla la tua email per resettare la password" })

    } catch (error) {
        history.push('/login', { 'failReason': "Richiesta fallita, hai inserito la email correttamente?" })
    }
  };


  return (
    <Container>
      <Form onSubmit={handleSubmit} className="mt-5">
        <h2 className="mb-5 mt-5" md='5' style={{ textAlign: "center" }}>Reimposta la tua password</h2>
        <Row className="justify-content-md-center mb-20">
            <Col md='5' style={{ textAlign: "center", marginBottom: "20px" }}>Inserisci la email con cui hai effettuato la registrazione con cui non riesci ad accedere, riceverai una email con un link per impostare una nuova password</Col>
        </Row>
        <Row className="mb-40 justify-content-md-center">
          <Form.Group as={Col} md="5" controlId="formBasicEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              required
              type="email"
              placeholder="Inserisci la email"
              value={username}
              onChange={onUsernameChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-3 mt-3 justify-content-md-center">
          <Col md="5">
            <Button
              className="col-md-12"
              size="lg"
              variant="primary"
              disabled={buttonDisabled}
              type="submit">
              Invia Richiesta
            </Button>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default RequestReset;
