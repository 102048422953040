import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { SENTRY_URL } from './config';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Footer from './Components/NewFooter/footer';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
  dsn: SENTRY_URL ,
  integrations: [new BrowserTracing()],
  allowUrls:[
    /https:\/\/corsi\.croas-sicilia\.it/,
    /http:\/\/localhost:3000/
  ],
  
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (
      error &&
      error.message &&
      (
      error.message.match(/EncodingError/i)
      )
    ){
      return null
    }
    else return event
  },
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
    <Footer></Footer>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
