

import React, { useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import { uuid4 } from "@sentry/utils";
import { useLocation, useHistory } from "react-router-dom";
const ErrorReportButton = () => {

    const [debug, setDebug] = useState(false);
    let location = useLocation()
    let history = useHistory()

    useEffect(() => {
        if( localStorage.getItem('Webinardebug')){
            setDebug(true);
        }
        else{
            setDebug(false);
        }
    }, [location])


    const errore = () => {
        localStorage.removeItem('Webinardebug');
        let code = uuid4();
        Sentry.captureException(code);
        history.push('/', {'successReason': 'segnalazione inviata, comunica questo codice all\' assistenza '+ code});
    }
    
    return  (
    <div>
        {debug  ? <button onClick={errore}>Invia segnalazione errore</button> : null}
    </div>
    )
};

export default ErrorReportButton;

