import React from "react";
import './footer.css'

const Footer = (props) => {


    return (
        <footer className='footer'>
            <div className="footer-content">
                <div className="top">
                    <div className="logo-details">
                        <img src="/logocroas.png" className="footerLogo" />
                        <span className="logo_name">Webinar Croas Sicilia</span>
                    </div>
                </div>
                <div className="link-boxes">
                    <ul className="box">
                        <li className="link_name">Contatti</li>
                        {/* <li><a href="#">Email: info@croas-sicilia.it</a></li>
                        <li><a href="#">Tel: 091 6488805</a></li> */}
                        <li><a href="#">Assistenza tecnica: info@ceformedsrl.it</a></li>
                    </ul>
                </div>
            </div>



            <div className="bottom-details">
                <div className="bottom_text">

                    <span className="copyright_text">Copyright © 2022 <a href="https://ceformedsrl.it">Ceformed srl.</a>All rights reserved</span>

                    <span className="policy_terms">
                        Contatto per problemi tecnici: info@ceformedsrl.it
                    </span>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
