// const API_URL= "http://192.168.1.178:8000"
// const SENTRY_URL="https://7aa5733a8a3644db9ad02611bb29d43f@o1158479.ingest.sentry.io/6241651"

const API_URL= "https://api.croas.ceformedsrl.it"
const SENTRY_URL="https://1ed782735245409ba25d5b9a32251beb@o1158479.ingest.sentry.io/6241656"

const CAPTCHA_SITEKEY = "6LeRT58eAAAAAHz1iznLq6nLIVWh9-t_0SGIbUtF"

const _API_URL = API_URL
const _SENTRY_URL =SENTRY_URL
const _CAPTCHA_SITEKEY = CAPTCHA_SITEKEY


export { _API_URL as API_URL }
export { _CAPTCHA_SITEKEY as CAPTCHA_SITEKEY}
export{_SENTRY_URL as SENTRY_URL}
