import { useEffect } from "react";
import { useHistory } from "react-router-dom";
const ErrorReporter = () => {
    let  history = useHistory();

    useEffect(() => {
        localStorage.setItem('Webinardebug', 'true');
        history.push('/about');
    })

    return null
};

export default ErrorReporter;


